import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import scrollToSection from 'utils/scrollToSection';

const LinkItem = styled.li`
  position: relative;
  display: none;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.subFont};
  font-weight: ${({ theme }) => theme.regular};
  white-space: nowrap;
  font-size: 2.2rem;
  cursor: pointer;
  transition: 0.3s;
  margin: 20px 0;

  @media (hover: hover) {
    &:hover {
      color: ${({ theme }) => theme.colors.pink};
      &:after {
        transform: translateX(-50%) scaleX(1);
        ${({ theme }) => theme.mq.xl} {
          transform: scaleX(1);
        }
      }
    }
  }
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -10px;
    width: 80px;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.pink};
    transition: 0.3s;
    transform: translateX(-50%) scaleX(0);
    transform-origin: 50% 50%;
    ${({ theme }) => theme.mq.xl} {
      width: 50px;
      left: 0;
      transform-origin: 0 50%;
      transform: scaleX(0);
    }
  }
  ${({ theme }) => theme.mq.xl} {
    display: block;
    margin: 0 10px;
    font-size: 2.1rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    margin: 0 25px;
    font-size: 2.2rem;
  }
`;

const List = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  ${({ theme }) => theme.mq.xl} {
    flex-direction: row;
    margin-left: 20px;
  }
  ${({ theme }) => theme.mq.xxl} {
    margin-left: 40px;
  }
  ${({ small }) =>
    small &&
    css`
      display: grid;
      grid-template-columns: repeat(4, auto);
      gap: 35px;
      justify-content: space-between;
      margin-left: 0 !important;
      padding: 5px 0 20px;
      & > ${LinkItem} {
        margin: 0 !important;
        font-size: 1.9rem;
      }
    `}
  ${({ isSidenav }) =>
    isSidenav &&
    css`
      & > ${LinkItem} {
        display: block !important;
      }
    `}
`;

const StyledLink = styled.a`
  color: inherit;
  text-decoration: none;
`;

const Links = ({ sidenav, close, small }) => {
  const location =
    typeof window !== 'undefined' ? window.location.pathname : '';

  return (
    <>
      <List small={small} isSidenav={sidenav}>
        <LinkItem onClick={() => close('#o-inwestycji')}>
          <StyledLink
            onClick={() => {
              if (location === '/') {
                scrollToSection('#o-inwestycji');
                return;
              }
              window.location.href = `/#o-inwestycji`;
            }}
          >
            O inwestycji
          </StyledLink>
        </LinkItem>
        <LinkItem onClick={() => close('#lokalizacja')}>
          <StyledLink
            onClick={() => {
              if (location === '/') {
                scrollToSection('#lokalizacja');
                return;
              }
              window.location.href = `/#lokalizacja`;
            }}
          >
            Lokalizacja
          </StyledLink>
        </LinkItem>
        {/* <LinkItem onClick={() => close('#mieszkania')}>
          <StyledLink
            onClick={() => {
              if (location === '/') {
                scrollToSection('#mieszkania');
                return;
              }
              window.location.href = `/#mieszkania`;
            }}
          >
            Mieszkania
          </StyledLink>
        </LinkItem> */}
        <LinkItem onClick={() => close('#kontakt')}>
          <StyledLink
            onClick={() => {
              if (location === '/') {
                scrollToSection('#kontakt');
                return;
              }
              window.location.href = `/#kontakt`;
            }}
          >
            Kontakt
          </StyledLink>
        </LinkItem>
        {/* <LinkItem onClick={() => close('#address')}>
          <StyledLink onClick={() => scrollToSection('#address')}>
            Dane adresowe
          </StyledLink>
        </LinkItem> */}
      </List>
    </>
  );
};

Links.propTypes = {
  sidenav: PropTypes.bool,
  small: PropTypes.bool,
  close: PropTypes.func,
};

Links.defaultProps = {
  close: () => null,
  small: false,
  sidenav: false,
};

export default Links;
